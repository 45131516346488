import React, { ReactElement } from "react";
import Image from "next/image";
export default function WhyTopBusinessQuotes(): ReactElement {
    return (
        <section className="flex lg:flex-row flex-col lg:mt-32 mt-20 lg:mb-40 mb-24   lg:gap-32 gap-8">
            <div className="max-w-[545px] mx-auto lg:mx-0">
                <h2 className="font-bold lg:text-[28px] text-xl leading-7 lg:leading-[42px] text-[#063C58]">
                    Why Choose TopBusinessQuotes.com?
                </h2>
                <span className="block w-[166px] h-[7px] bg-[#FCB02F] mt-3"></span>
                <p className=" lg:mt-10 mt-5 lg:text-lg ">
                    In today's fast-paced business landscape, we recognize the
                    unique challenges that businesses like yours face. That's
                    precisely why we've developed TopBusinessQuotes.com - a
                    cutting-edge platform designed to simplify and expedite the
                    process of obtaining quotes for vital products and services.
                    With just a few effortless clicks, you can compare quotes
                    from a diverse range of providers and cherry-pick the one
                    that aligns best with your business requirements. This
                    streamlined approach not only saves you valuable time but
                    also ensures that you secure the most favorable deals in the
                    market.
                </p>
                <p className="lg:mt-10 mt-5 ">
                    <h3 className=" lg:text-xl font-bold">What We Give You:</h3>
                    <ul className=" lg:text-lg list-disc list-inside">
                        <li>
                            <strong>Efficiency: </strong>
                            Streamline your procurement process and save time.
                        </li>
                        <li>
                            <strong>Cost Savings: </strong>
                            Access competitive quotes to optimize your budget.
                        </li>
                        <li>
                            <strong>Transparency: </strong>
                            Make informed decisions with accurate, up-to-date
                            information.
                        </li>
                        <li>
                            <strong>Diverse Options: </strong>
                            Explore a wide array of providers to find the
                            perfect fit.
                        </li>
                        <li>
                            <strong>Expert Guidance: </strong>
                            Benefit from the insights of our dedicated team of
                            experts.
                        </li>
                        <li>
                            <strong>Risk-Free: </strong>
                            Our service is entirely free, with no obligation on
                            your part.
                        </li>
                        <li>
                            <strong>Convenience: </strong>
                            Enjoy the ease of meeting all your business needs in
                            one centralized location.
                        </li>
                    </ul>
                </p>
            </div>
            <div className="w-[300px] h-[200px] lg:w-[500px] lg:min-w-[500px] lg:h-[420px] relative lg:mt-20 mx-auto lg:mx-0">
                <Image
                    src={"/topbusinessquotes/assets/images/whyUsImage.jpg"}
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
